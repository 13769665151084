import React, { useState, useEffect } from "react";
import Contract from "./Contract.js";
import Bill_Account from "./Bill_Account.js";
import "./Tasks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faIndustry,
  faInfoCircle,
  faSpinner,
  faTimesCircle,
  faCheckCircle,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faQuestionCircle,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import { pdf } from "@react-pdf/renderer";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Cookies from "universal-cookie";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import { Cities } from "../../../App/services/services";
import { useLocation, Link, useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { url_crm_quote } from "../../constants/constants.js";

function Tasks() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const codigo_vendedor = cookies.get("codigo_vendedor");
  const super_user = cookies.get("super_user");

  const history = useHistory();

  const [responseTasks, setResponseTasks] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog4, setOpenDialog4] = useState(false);

  const [fetchSuccess, setFetchSuccess] = useState(false);

  const [sortAscen, setSortAscen] = useState("asc");

  const [open_contract_modal, setOpen_contract_modal] = useState(false);
  const [open_confirm_modal, setOpen_confirm_modal] = useState(false);
  const [index_clicked, setIndex_clicked] = useState();
  const [file, setfile] = useState();
  const [email_admin, emaiSent] = useState(false);
  const [email_adminconfirm, emaiSentconfirm] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const [oc, setOc] = useState("");
  const [fce, setFce] = useState("");
  const [details_team, setDetails_team] = useState("");
  const [nrocot, setnrocot] = useState();
  const [sendLogistic, setSendLogistic] = useState(true);
  const [advancePercentage, setAdvancePercentage] = useState();
  const [creditDays, setCreditDays] = useState();
  const [deliveryDays, setDeliveryDays] = useState();

  const [statusbyemai, setstatusbyemail] = useState("");

  const [isLoading_contract, setIsloading_contract] = useState(true);
  const [json_response_contract, setJson_response_contract] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [error409, setError409] = useState(false);
  const [documents_ready, setDocuments_ready] = useState(false);

  const open_actualizar = (event, index) => {
    const listTasks = [...responseTasks];
    listTasks[index]["act"] = !listTasks[index]["act"];
    setResponseTasks(listTasks);
    setnrocot(listTasks[index]["nro_cotizacion"]);
  };

  useEffect(() => {
    setFce(activeRow?.fce);
  }, [activeRow]);

  const handlechange_lastTask = (event, index) => {
    const { name, value } = event.target;
    const listTasks = [...responseTasks];
    listTasks[index].last_task[name] = value;
    setResponseTasks(listTasks);
  };

  const handlechange = (event, index) => {
    const { name, value } = event.target;
    const listTasks = [...responseTasks];
    listTasks[index][name] = value;
    setResponseTasks(listTasks);
  };

  const handleChange_prioridad = (event, index) => {
    const { name, value } = event.target;
    const listTasks = [...responseTasks];
    listTasks[index][name] = value;
    setResponseTasks(listTasks);
    const nro_cot = listTasks[index]["nro_cotizacion"];

    fetch(`${global.url_back}/quote_update_priority/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nro_cotizacion: nro_cot,
        prioridad: value,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("error update_proprity_tasks");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  };

  const handlechange_estado = (event, index) => {
    const { name, value } = event.target;
    const listTasks = [...responseTasks];

    if (value === "fin") {
      listTasks[index]["estado_fin"] = "aceptada";
      listTasks[index]["motivo"] = "Precio";
    } else {
      listTasks[index]["estado_fin"] = "";
      listTasks[index]["motivo"] = "";
    }

    listTasks[index][name] = value;
    setResponseTasks(listTasks);
  };

  const send_task = (nro_cotizacion, index) => {
    const listTasks = [...responseTasks];

    const task = listTasks[index];
    task["act"] = false;

    console.log(task);

    if (task.last_task.nueva_act === undefined) {
      task.last_task["nueva_act"] = moment().format("YYYY-MM-DD");
    }

    fetch(`${global.url_back}/post_task/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    })
      .then((response) => {
        if (response.status === 200) {
          if (listTasks[index]["estado_sgto"] === "fin") {
            if (searchWord !== "") {
              //this if is to avoid a bug when filter and make a task "finalizada"

              history.go(0);
            } else {
              listTasks.splice(index, 1);
              setResponseTasks(listTasks);
            }
          } else {
            task.last_task["fecha_actualizacion"] = task.last_task["nueva_act"];
            task.last_task["fecha_seguimiento"] = task.last_task["proxSgto"];
            task.last_task["observaciones"] =
              task.last_task["newobservaciones"];
            task.last_task["nueva_act"] = moment().format("YYYY-MM-DD");
            task.last_task["proxSgto"] = null;
            task.last_task["newobservaciones"] = "";

            if (
              task["color"] === "yellow" &&
              task["estado_sgto"] === "proceso"
            ) {
              task["color"] = "blue";
            } else if (
              (task["color"] === "yellow" || task["color"] === "blue") &&
              task["estado_sgto"] === "confirmado"
            ) {
              task["color"] = "green";
            }

            listTasks[index] = task;
            setResponseTasks(listTasks);
          }
        } else {
          console.log("error tasks");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  };

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    fetch(`${global.url_back}/get_tasks/${codigo_vendedor}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setResponseTasks(json_response.data);
            setBackupTasks(json_response.data);
          });
        } else {
          console.log("error tasks");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  }, []);

  const Send_mailadmmin = (ocmail, fcemail, details_team, nro_cot) => {
    var body = JSON.stringify({
      nro_cotizacion: nro_cot,
      oc: ocmail,
      fce: fcemail,
      codigo_vendedor: codigo_vendedor,
      estado: statusbyemai,
      sendLogistic: sendLogistic,
      details_team: details_team,
      advance_percentage: advancePercentage,
      credit_days: creditDays,
      delivery_days: deliveryDays,
    });
    fetch(`${global.url_back}/send_mailadmin/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("error update_proprity_tasks");
          emaiSent(false);
          window.location.reload();

          setFetchSuccess(false);
          setOpenDialog(true);
        } else if (response.status === 200) {
          emaiSent(false);
          emaiSentconfirm(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  };

  const get_Quote = (nro_cotizacion) => {
    fetch(`${global.url_back}/get_quote_detail/?pk=${nro_cotizacion}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setIsSubmitting(false);
            history.push(`${url_crm_quote}/quoteadd`, {
              state: { json_response: json_response, nro_cotizacion: nro_cotizacion },
            });
          });
        } else {
          console.log("error Quote-query");
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
        setIsSubmitting(false);
      });
  };

  const [backupTasks, setBackupTasks] = useState();
  const [searchWord, setSearchWord] = useState("");
  const [clickSort, setClickSort] = useState();

  const handleSearch = (value) => {
    // let oldsTasks = backupTasks.map((row) => {
    //   return { ...row, contacto: row.contacto.toLowerCase() };
    // });
    let oldsTasks = backupTasks;
    if (value !== "") {
      setSearchWord(value);
      let newList = [];
      newList = oldsTasks.filter(
        (row) =>
          row.contacto.toLowerCase().includes(value.toLowerCase()) ||
          row.id_cliente.nombre_cliente
            .toLowerCase()
            .includes(value.toLowerCase())
      );
      console.log(newList);
      setResponseTasks(newList);
    } else {
      setResponseTasks(backupTasks);
    }
  };

  const sortFunction = (sub1, sub2 = "") => {
    setSortAscen(!sortAscen);
    const listTasks = [...responseTasks];

    if (sub2 !== "") {
      listTasks.sort((a, b) => a[sub1][sub2].localeCompare(b[sub1][sub2]));
    } else {
      listTasks.sort((a, b) => a[sub1].localeCompare(b[sub1]));
    }

    if (sortAscen) {
      setResponseTasks(listTasks);
    } else {
      setResponseTasks(listTasks.reverse());
    }
  };

  const sortFunction_number = (sub1) => {
    setSortAscen(!sortAscen);
    const listTasks = [...responseTasks];

    listTasks.sort((a, b) => {
      if (a[sub1] < b[sub1]) {
        return -1;
      }
      if (a[sub1] > b[sub1]) {
        return 1;
      }
      return 0;
    });

    if (sortAscen) {
      setResponseTasks(listTasks);
    } else {
      setResponseTasks(listTasks.reverse());
    }
  };

  const sortFunction_proxSgto = (sub1, sub2) => {
    setSortAscen(!sortAscen);

    const listTasks = [...responseTasks];

    listTasks.sort((a, b) => {
      if (a[sub1][sub2] === null) {
        return 1;
      }
      if (b[sub1][sub2] === null) {
        return -1;
      }
      if (a[sub1][sub2] < b[sub1][sub2]) {
        return -1;
      }
      if (a[sub1][sub2] > b[sub1][sub2]) {
        return 1;
      }
      return 0;
    });
    if (sortAscen) {
      setResponseTasks(listTasks);
    } else {
      setResponseTasks(listTasks.reverse());
    }
  };

  const Dialog_Confirm = () => {
    return (
      <Dialog
        onClose={() => {
          setOpen_confirm_modal(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={open_confirm_modal}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="2x"
            style={{ color: "f2d326" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea salir sin generar documentos?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpen_confirm_modal(false);
              setOpen_contract_modal(false);
            }}
            color="primary"
          >
            Salir
          </button>
          <button
            onClick={() => setOpen_confirm_modal(false)}
            color="primary"
            autoFocus
          >
            Cancelar
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const get_info_contract = (index) => {
    setIndex_clicked(index);

    setOpen_contract_modal(true);
    setIsloading_contract(true);

    fetch(
      `${global.url_back}/get_contract_detail/?pk=${responseTasks[index]?.nro_cotizacion}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setIsloading_contract(false);
          return response.json().then((json_response) => {
            console.log(json_response);
            setJson_response_contract(json_response);
          });
        } else if (response.status === 409) {
          setIsloading_contract(false);
          setError409(true);
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog(true);
        } else {
          setIsloading_contract(false);
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog(true);
          console.log("error fetchingform-clientAdd");
        }
      })
      .catch((error) => {
        setIsloading_contract(false);
        setIsSubmitting(false);
        setFetchSuccess(false);
        setOpenDialog(true);
        console.log(error);
      });
  };

  async function generate_docs(values_form, data_detail, data_service) {
    setIsloading_contract(true);
    const contract = (
      <Contract
        values_form={values_form}
        data_detail={data_detail}
        data_service={data_service}
      />
    );
    const bill_account = (
      <Bill_Account values_form={values_form} data_detail={data_detail} />
    );

    const asPdf_contract = pdf(); // {} is important, throws without an argument
    asPdf_contract.updateContainer(contract);
    const blob_contract = await asPdf_contract.toBlob();
    setfile(blob_contract);
    saveAs(
      blob_contract,
      `Contrato de alquiler ${formik.values.client_name}.pdf`
    );

    const asPdf_bill_account = pdf(); // {} is important, throws without an argument
    asPdf_bill_account.updateContainer(bill_account);
    const blob_bill_account = await asPdf_bill_account.toBlob();
    setfile(blob_bill_account);
    saveAs(
      blob_bill_account,
      `Cobro deposito alquiler ${formik.values.cuenta_cobro}-${new Date()
        .getFullYear()
        .toString()
        .substr(-2)} ${formik.values.client_name}.pdf`
    );
    setIsloading_contract(false);
    setDocuments_ready(true);
  }

  const today = new Date();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      client_name: responseTasks[index_clicked]?.id_cliente.nombre_cliente,
      nit: responseTasks[index_clicked]?.id_cliente.nroid_cliente,
      valor_canon_mes: json_response_contract?.data_totals?.valor_canon_mes,
      valor_deposito: Math.round(
        json_response_contract?.data_totals?.valor_canon_mes * 0.7
      ),
      fecha_firma: moment().format("YYYY-MM-DD"),
      municipio: responseTasks[index_clicked]?.municipio,
      departamento: responseTasks[index_clicked]?.departamento,
    },
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      setIsSubmitting(true);
      generate_docs(
        formik.values,
        json_response_contract.data_detail,
        json_response_contract.data_service
      );
    },
  });

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      if (txt == "de") {
        return txt;
      } else {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    });
  }

  return (
    <div className="container__tasks">
      <Dialog
        onClose={() => {
          setOpenDialog4(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog4}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Debes diligenciar los campos orden de compra y correo de facturacion
            electronica para enviar el correo.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          emaiSentconfirm(false);
          window.location.reload();
        }}
        aria-labelledby="simple-dialog-title"
        open={email_adminconfirm}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Correo enviado con exito.</DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog(false);
          if (error409) {
            setOpen_contract_modal(false);

            setTimeout(() => setError409(false), 1000);
          }
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          {fetchSuccess ? (
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccess
              ? "Cliente creado con Exito"
              : ` ${
                  error409
                    ? "Esta cotizacion no contiene items de Alquiler"
                    : "Algo salio mal, intenta nuevamente"
                }`}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* email administ dialog */}
      <Dialog
        open={email_admin}
        onClose={() => {
          emaiSent(false);
          setOc("");
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Dialog_Confirm />
        <div className="email_modal">
          <h2 align="center">Confirmación de cotización</h2>

          <div className="email_modal_form">
            <div className="email_modal_form_column">
              <div className="email_modal_form_column_top">
                <div
                  className="email_modal_form_column_top_box"
                  style={{ flex: activeRow?.has_sale_items ? 0.55 : 1 }}
                >
                  <p className="p_contract_form">Numero Orden de compra</p>
                  <input
                    type="text"
                    name="client_name"
                    className="input_contract_form"
                    onChange={(event) => setOc(event.target.value)}
                    value={oc}
                  />
                  <p className="p_contract_form">
                    Correo facturación electrónica
                  </p>
                  <input
                    type="email"
                    name="client_name"
                    className="input_contract_form"
                    onChange={(event) => setFce(event.target.value)}
                    value={fce}
                  />
                </div>
                {activeRow?.has_sale_items && (
                  <div
                    className="email_modal_form_column_top_box"
                    style={{ flex: 0.4 }}
                  >
                    <div className="flex_space_between">
                      <div style={{ flex: 0.45 }}>
                        <p className="p_contract_form">% Anticipo</p>
                        <input
                          type="number"
                          name="advance_percentage"
                          className="input_contract_form"
                          onChange={(event) =>
                            setAdvancePercentage(parseInt(event.target.value))
                          }
                          value={advancePercentage}
                        />
                      </div>
                      <div style={{ flex: 0.45 }}>
                        <p className="p_contract_form">Crédito</p>
                        <input
                          type="number"
                          name="credit_days"
                          className="input_contract_form"
                          onChange={(event) =>
                            setCreditDays(parseInt(event.target.value))
                          }
                          value={creditDays}
                        />
                      </div>
                      <div>
                        <p
                          className="p_contract_form"
                          style={{ marginBottom: "-3px" }}
                        >
                          Días Corridos
                        </p>
                      </div>
                    </div>
                    <div className="flex_space_between">
                      <div style={{ flex: 0.95 }}>
                        <p className="p_contract_form">Tiempo de Entrega</p>
                        <input
                          type="number"
                          name="delivery_days"
                          className="input_contract_form"
                          onChange={(event) =>
                            setDeliveryDays(parseInt(event.target.value))
                          }
                          value={deliveryDays}
                        />
                      </div>

                      <div>
                        <p
                          className="p_contract_form"
                          style={{ marginBottom: "-3px" }}
                        >
                          Días Corridos
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="email_modal_form_column_bottom">
                <p className="p_contract_form">Detalles</p>
                <textarea
                  style={{ boxSizing: "border-box", padding: "4px" }}
                  type="text"
                  name="details_team"
                  className="input_contract_form"
                  onChange={(event) => setDetails_team(event.target.value)}
                  value={details_team}
                />
                <div className="simple_flex">
                  <input
                    type="checkbox"
                    name="client_name"
                    onChange={() => setSendLogistic(!sendLogistic)}
                    checked={sendLogistic}
                  />
                  <p
                    className="p_contract_form"
                    style={{ margin: "0 0 0 5px", fontSize: "16px" }}
                  >
                    Enviar a logística
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            // style={{ margin: "0 0 0 auto" }}
            onClick={() => {
              if (oc !== "" && fce !== "" && nrocot !== "") {
                Send_mailadmmin(oc, fce, details_team, nrocot);
              } else {
                setOpenDialog4(true);
              }
            }}
            type="button"
          >
            Enviar Correo
          </button>
        </div>
      </Dialog>
      {/* contract dialog */}
      <Dialog
        open={open_contract_modal}
        onClose={() => {
          if (documents_ready) {
            setOpen_contract_modal(false);
            setDocuments_ready(false);
          } else {
            setOpen_confirm_modal(true);
          }
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <Dialog_Confirm />

        <div className="contract_modal">
          <h2 align="center">Contrato y Cuenta de Cobro</h2>
          <form onSubmit={formik.handleSubmit}>
            {isLoading_contract ? (
              <div className="contract_modal_form">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="4x"
                  className="fa-spin"
                  color="#266e45"
                />
              </div>
            ) : (
              <>
                <div className="contract_modal_form">
                  <div className="contract_modal_form_column">
                    <p className="p_contract_form">Cliente</p>
                    <input
                      type="text"
                      disabled
                      value={formik.values.client_name}
                      name="client_name"
                      className="input_contract_form"
                    />
                    <p className="p_contract_form">Nit</p>
                    <input
                      type="text"
                      disabled
                      value={formik.values.nit}
                      name="client_name"
                      className="input_contract_form"
                    />
                    <p className="p_contract_form">
                      Ciudad Cámara de Comercio.
                    </p>
                    <input
                      required
                      type="text"
                      value={formik.values.camara_comercio}
                      onChange={formik.handleChange}
                      name="camara_comercio"
                      className="input_contract_form"
                      list="ciudades"
                    />
                    <p className="p_contract_form">Representante Legal</p>
                    <input
                      required
                      type="text"
                      value={formik.values.representante_legal}
                      onChange={formik.handleChange}
                      name="representante_legal"
                      className="input_contract_form"
                    />
                    <div className="flex_space_between">
                      <div style={{ flex: 0.65 }}>
                        <p className="p_contract_form">
                          {`${
                            formik.values.tipo_documento
                              ? toTitleCase(formik.values.tipo_documento)
                              : "Nro Id."
                          } Representante Legal`}
                        </p>
                        <input
                          required
                          type="number"
                          value={formik.values.id_representante_legal}
                          onChange={formik.handleChange}
                          name="id_representante_legal"
                          className="input_contract_form"
                        />
                      </div>
                      <div style={{ flex: 0.3 }}>
                        <p className="p_contract_form">Tipo Documento</p>
                        <select
                          required
                          value={formik.values.tipo_documento}
                          onChange={formik.handleChange}
                          name="tipo_documento"
                          className="modal_select"
                          style={{ marginBottom: "10px" }}
                        >
                          <option value="">-</option>
                          <option value="cedula de ciudadanía">C.C</option>
                          <option value="cedula de extranjería">C.E</option>
                          <option value="pasaporte">Pasaporte</option>
                        </select>
                      </div>
                    </div>
                    <p className="p_contract_form">Dirección Exacta</p>
                    <input
                      required
                      type="text"
                      value={formik.values.direccion_exacta}
                      onChange={formik.handleChange}
                      name="direccion_exacta"
                      className="input_contract_form"
                    />
                  </div>
                  <div className="contract_modal_form_column">
                    <div className="flex_space_between">
                      <div style={{ flex: 0.45 }}>
                        <p className="p_contract_form">
                          Departamento de Destino
                        </p>
                        <input
                          required
                          type="text"
                          value={formik.values.departamento}
                          onChange={formik.handleChange}
                          name="departamento"
                          className="input_contract_form"
                        />
                      </div>
                      <div style={{ flex: 0.45 }}>
                        <p className="p_contract_form">Municipio de Destino</p>
                        <input
                          required
                          type="text"
                          value={formik.values.municipio}
                          onChange={formik.handleChange}
                          name="municipio"
                          className="input_contract_form"
                        />
                      </div>
                    </div>

                    <p className="p_contract_form">Canon Mensual</p>
                    {isEditing ? (
                      <input
                        required
                        type="number"
                        className="input_contract_form"
                        name="valor_canon_mes"
                        value={formik.values.valor_canon_mes}
                        onChange={formik.handleChange}
                        onBlur={() => setIsEditing(!isEditing)}
                      />
                    ) : (
                      <input
                        required
                        type="text"
                        className="input_contract_form"
                        value={toCurrency(formik.values.valor_canon_mes)}
                        onFocus={() => setIsEditing(!isEditing)}
                      />
                    )}
                    <p className="p_contract_form">Valor Deposito 70%</p>
                    {isEditing ? (
                      <input
                        required
                        type="number"
                        className="input_contract_form"
                        name="valor_deposito"
                        value={formik.values.valor_deposito}
                        onChange={formik.handleChange}
                        onBlur={() => setIsEditing(!isEditing)}
                      />
                    ) : (
                      <input
                        required
                        type="text"
                        className="input_contract_form"
                        name="valorUnit"
                        value={toCurrency(formik.values.valor_deposito)}
                        onFocus={() => setIsEditing(!isEditing)}
                      />
                    )}
                    <p className="p_contract_form">Ciudad Donde se Firma</p>
                    <input
                      required
                      type="text"
                      value={formik.values.ciudad_firma}
                      onChange={formik.handleChange}
                      name="ciudad_firma"
                      className="input_contract_form"
                      list="ciudades"
                    />
                    <p className="p_contract_form">Fecha Firma</p>
                    <input
                      required
                      type="date"
                      value={formik.values.fecha_firma}
                      onChange={formik.handleChange}
                      name="fecha_firma"
                      className="input_contract_form"
                    />
                    <p className="p_contract_form">Nro Cuenta de Cobro</p>
                    <input
                      required
                      type="text"
                      value={formik.values.cuenta_cobro}
                      onChange={formik.handleChange}
                      name="cuenta_cobro"
                      className="input_contract_form"
                    />
                    <Cities id="ciudades" />
                  </div>
                </div>

                <button style={{ margin: "0 0 0 auto" }} type="submit">
                  Generar Documentos
                </button>
              </>
            )}
          </form>
        </div>
      </Dialog>
      <div className="top-bar">
        <h3>Seguimiento Cotizaciones</h3>
      </div>
      <div className="search_div">
        <div className="search_container">
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
        <div className="guides">
          <div className="sub_guide">
            <div
              className="square_estado"
              style={{ backgroundColor: "red", marginRight: "5px" }}
            ></div>
            <p>Borrador</p>
          </div>
          <div className="sub_guide">
            <div
              className="square_estado"
              style={{ backgroundColor: "yellow", marginRight: "5px" }}
            ></div>
            <p>Creada</p>
          </div>
          <div className="sub_guide">
            <div
              className="square_estado"
              style={{ backgroundColor: "blue", marginRight: "5px" }}
            ></div>
            <p>En Sgto</p>
          </div>
          <div className="sub_guide">
            <div
              className="square_estado"
              style={{ backgroundColor: "green", marginRight: "5px" }}
            ></div>
            <p>Confirmado</p>
          </div>
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <th className="thestado"> </th>
            <th
              className="thcodigo"
              onClick={() => {
                sortFunction_number("codigo_vendedor");
                setClickSort("codigo_vendedor");
              }}
            >
              Codigo
              {clickSort === "codigo_vendedor" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>
            <th
              className="thNoCot"
              onClick={() => {
                sortFunction_number("nro_cotizacion");
                setClickSort("noCot");
              }}
            >
              No.Cot
              {clickSort === "noCot" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>
            <th
              className="thCliente"
              onClick={() => {
                sortFunction("id_cliente", "nombre_cliente");
                setClickSort("cliente");
              }}
            >
              Cliente{" "}
              {clickSort === "cliente" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>
            <th
              className="thContacto"
              onClick={() => {
                sortFunction("contacto");
                setClickSort("contacto");
              }}
            >
              Contacto
              {clickSort === "contacto" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>
            <th
              className="thUltimoSgto"
              onClick={() => {
                sortFunction("last_task", "fecha_actualizacion");
                setClickSort("ultimoSgto");
              }}
            >
              Ultimo Sgto
              {clickSort === "ultimoSgto" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>
            <th
              className="thProxSgto"
              onClick={() => {
                sortFunction_proxSgto("last_task", "fecha_seguimiento");
                setClickSort("proxSgto");
              }}
            >
              Prox Sgto
              {clickSort === "proxSgto" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>
            <th
              className="thPrioridad"
              onClick={() => {
                sortFunction("prioridad");
                setClickSort("prioridad");
              }}
            >
              Prioridad
              {clickSort === "prioridad" ? (
                sortAscen ? (
                  <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
                )
              ) : null}
            </th>

            <th className="thActualizar">Actualizar</th>
            <th className="thDetalle">Detalle</th>
          </tr>
          {responseTasks?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>
                  <div className="wrapper-estado">
                    <div
                      className="square_estado"
                      style={{ backgroundColor: `${row.color}` }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div className="wrapper-icon-codigo">
                    <FontAwesomeIcon
                      icon={faUserAlt}
                      color="#383838"
                      style={{ fontSize: "18px" }}
                    />
                    <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                      {row.codigo_vendedor}
                    </div>
                  </div>
                </td>
                <td>{row.nro_cotizacion}</td>

                <td>{row.id_cliente.nombre_cliente}</td>
                <td>{row.contacto}</td>
                <td>{row.last_task.fecha_actualizacion}</td>
                <td>
                  {row.last_task.fecha_seguimiento === null ||
                  row.last_task.fecha_seguimiento === undefined
                    ? "Sin Fecha"
                    : row.last_task.fecha_seguimiento}
                </td>
                <td>
                  <select
                    className="selectPrioridad"
                    name="prioridad"
                    value={row.prioridad}
                    onChange={(event) => handleChange_prioridad(event, index)}
                  >
                    <option value="1baja">Baja</option>
                    <option value="2media">Media</option>
                    <option value="3alta">Alta</option>
                  </select>
                </td>
                <td>
                  <div
                    name="act"
                    onClick={(event) => open_actualizar(event, index)}
                  >
                    <FontAwesomeIcon
                      icon={faClipboard}
                      color="#383838"
                      className="iconRow"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => get_Quote(row.nro_cotizacion)}
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#383838"
                      className="iconRow"
                    />
                  </div>
                </td>
              </tr>
              {responseTasks[index]["act"] ? (
                <tr>
                  <td colspan="10" className="detailRow">
                    <div className="detail-line1">
                      <div className="line1__ultimoSgto">
                        <p className="p_detail">Fecha Sgto</p>
                        <input
                          type="date"
                          name="nueva_act"
                          value={
                            row.last_task.nueva_act === undefined
                              ? moment().format("YYYY-MM-DD")
                              : row.last_task.nueva_act
                          }
                          onChange={(event) =>
                            handlechange_lastTask(event, index)
                          }
                        />
                      </div>
                      <div className="line1__proxSgto">
                        <p className="p_detail">Prox Sgto</p>
                        <input
                          type="date"
                          name="proxSgto"
                          value={row.last_task.proxSgto}
                          onChange={(event) =>
                            handlechange_lastTask(event, index)
                          }
                        />
                      </div>
                      <div className="line1__estado">
                        <p className="p_detail">Estado</p>
                        <select
                          name="estado_sgto"
                          value={row.estado_sgto}
                          onChange={(event) =>
                            handlechange_estado(event, index)
                          }
                        >
                          <option value="proceso">Proceso</option>
                          <option value="confirmado">Confirmado</option>
                          <option value="fin">Finalizado</option>
                        </select>
                      </div>
                      {row.estado_sgto == "fin" ||
                      row.estado_sgto == "confirmado" ? (
                        <>
                          <div className="line1v1__estado">
                            <input
                              className="inputRadio__addclienttask"
                              type="checkbox"
                              id="Natural"
                              name="tipoPersona"
                              disabled={
                                responseTasks[index]["last_task"][
                                  "correo_confirmacion"
                                ]
                              }
                              value={email_admin}
                              checked={
                                email_admin ||
                                responseTasks[index]["last_task"][
                                  "correo_confirmacion"
                                ]
                              }
                              onClick={() => {
                                emaiSent(!email_admin);
                                setstatusbyemail(row.estado_sgto);
                                setActiveRow(row);
                              }}
                            />
                            <label className="p_detail">
                              Enviar correo de confirmación
                            </label>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="detail-line2">
                      <div className="line1__ultimoSgto">
                        <p className="p_detail">Observaciones Ultimo Sgto</p>
                      </div>
                      <div className="line1__proxSgto">
                        <p className="p_detail">Nro Contacto: </p>
                        <p
                          className="p_detail"
                          style={{ fontWeight: "normal", marginLeft: "5px" }}
                        >
                          {row.id_contacto.celular === ""
                            ? row.id_contacto.indic_telefono +
                              "-" +
                              row.id_contacto.telefono
                            : row.id_contacto.celular}
                        </p>
                      </div>
                      {row?.oc && (
                        <div className="line1__proxSgto">
                          <p className="p_detail">OC: </p>
                          <p
                            className="p_detail"
                            style={{ fontWeight: "normal", marginLeft: "5px" }}
                          >
                            {row.oc}
                          </p>
                        </div>
                      )}

                      {row.estado_sgto == "fin" ? (
                        <>
                          <div className="line1__estado">
                            <p className="p_detail">Fin</p>
                            <select
                              name="estado_fin"
                              value={row.estado_fin}
                              onChange={(event) => handlechange(event, index)}
                            >
                              <option value="aceptada">Aceptada</option>
                              <option value="rechazada">Rechazada</option>
                            </select>
                          </div>
                          <div className="line1__estado">
                            <p className="p_detail">Motivo</p>

                            <select
                              name="motivo"
                              value={row.motivo}
                              onChange={(event) => handlechange(event, index)}
                            >
                              {row.estado_fin === "aceptada" ? (
                                <>
                                  {/* If Price change as the first option, the function "handlechange_estado" has to change*/}
                                  <option value="Precio">Precio</option>
                                  <option value="Tiempo de Entrega">
                                    Tiempo de Entrega
                                  </option>
                                  <option value="Claridad">Claridad</option>
                                  <option value="Buen Servcio">
                                    Buen Servcio
                                  </option>
                                  <option value="Calidad">Calidad</option>
                                  <option value="Fidelización">
                                    Fidelización
                                  </option>
                                  <option value="Recomendación">
                                    Recomendación
                                  </option>
                                </>
                              ) : (
                                <>
                                  {/* If Price change as the first option, the function "handlechange_estado" has to change*/}
                                  <option value="Precio">Precio</option>
                                  <option value="Mal Servicio">
                                    Mal Servicio
                                  </option>
                                  <option value="Tiempo de Entrega">
                                    Tiempo de Entrega
                                  </option>
                                  <option value="Cancelación Contrato">
                                    Cancelación Contrato
                                  </option>
                                  <option value="Demora en Cotización">
                                    Demora en Cotización
                                  </option>
                                  <option value="Otro Proveedor">
                                    Otro Proveedor
                                  </option>
                                  <option value="No Contesto">
                                    No Contesto
                                  </option>
                                  <option value="No le Interesó">
                                    No le Interesó
                                  </option>
                                  <option value="Condiciones Comerciales">
                                    Condiciones Comerciales
                                  </option>
                                  <option value="Construcción Tradicional">
                                    Construcción Tradicional
                                  </option>
                                  <option value="Sin inventario">
                                    Sin inventario
                                  </option>
                                  <option value="Valor del transporte">
                                    Valor del transporte
                                  </option>
                                  <option value="No se hizo seguimiento">
                                    No se hizo seguimiento
                                  </option>
                                  <option value="Licitación">
                                    Licitación
                                  </option>
                                  <option value="Pasa a proyectos">
                                    Pasa a proyectos
                                  </option>
                                </>
                              )}
                            </select>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="detail-line3">
                      <div className="line3__ultimoSgtotxt">
                        <textarea
                          className="txtareaUltimoSgto"
                          value={
                            (row.tasks || [])  
                              .map((task) => task.observaciones)
                              .join("\n")
                          }
                          disabled
                        ></textarea>
                      </div>
                      <div className="line3__btn">
                        <button
                          style={{
                            margin: "0 auto 0 15px",
                            padding: "3px 15px",
                          }}
                          onClick={() => send_task(row.nro_cotizacion, index)}
                        >
                          Guardar
                        </button>

                        <button
                          style={{
                            margin: "30px auto 0 15px",
                            padding: "3px 15px",
                          }}
                          onClick={() => {
                            get_info_contract(index);
                          }}
                        >
                          Generar Contrato y CC
                        </button>
                      </div>
                    </div>
                    <div className="detail-line4">
                      <div className="line1__ultimoSgtotxt">
                        <p className="p_detail">Observaciones</p>
                      </div>
                    </div>
                    <div className="detail-line5">
                      <div className="line1__ultimoSgtotxt">
                        <textarea
                          style={{ resize: "none" }}
                          name="newobservaciones"
                          value={row.last_task.newobservaciones}
                          onChange={(event) =>
                            handlechange_lastTask(event, index)
                          }
                        ></textarea>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
            </>
          ))}
        </table>
      </div>
    </div>
  );
}

export default Tasks;
