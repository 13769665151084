import React, { useEffect, useState } from "react";
import "./Tools.css";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSignOutAlt,
  faUndo,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import ModalInfoMtto from "./Modal_info_mtto";
import ModalHerramientas from "./Modal_herramientas";
import ModalDevolucion from "./Modal_devolucion";
import ModalSalida from "./Modal_salida";
import { useCookies } from "react-cookie";
import Loading from "../../../App/components/Loading/Loading";

function Tools() {
  const today = new Date();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const [sortAscen, setSortAscen] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [isLoading, setIsLoading] = useState();
  const [openModalInfoMtto, setOpenModalInfoMtto] = useState(false);
  const [openModalHerramientas, setOpenModalHerramientas] = useState(false);
  const [openModalDevolucion, setOpenModalDevolucion] = useState(false);
  const [openModalSalida, setOpenModalSalida] = useState(false);
  const [filterAvailable, setFilterAvailable] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [multipleOutputItems, setMultipleOutputItems] = useState([]);
  const [isMultipleOutput, setIsMultipleOutput] = useState(false);
  const [initValuesModalInfoMtto, setInitValuesModalInfoMtto] = useState();
  const [initValuesModalDevolucion, setInitValuesModalDevolucion] = useState();
  const [initValuesModalSalida, setInitValuesModalSalida] = useState();
  const [initValuesModalHerramientas, setInitValuesModalHerramientas] =
    useState();
  const [cookies] = useCookies();
  const token = cookies.token_inventario;
  const super_user_inventario = cookies.super_user_inventario;

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response];
    listTasks.sort((a, b) =>
      a[sub].toString().localeCompare(b[sub].toString())
    );
    if (!sortAscen) {
      setJson_response(listTasks.reverse());
    }
    setJson_response(listTasks);
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const InfoMaintenance = (row) => {
    if (row.en_mantenimiento) {
      setInitValuesModalInfoMtto({
        ...row,
      });
      setOpenModalInfoMtto(true);
    }
  };

  async function send_init_request() {
    setIsLoading(true);
    const response = await fetch_backend("tools/", "GET", token, dispatch);
    setJson_response(response);
    setBackupJson_response(response);
    setIsLoading(false);
  }

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request();
  }, []);

  const handleSearch = (value) => {
    var List = [...backupJson_response.tools];
    var newList = [];
    value = value.toString().toLowerCase();
    if (value !== "") {
      for (var i = 0; i < List.length; i++) {
        var family = {};
        family.familia = List[i].familia;
        family.color = List[i].color;
        var new_items = List[i].items.filter(
          (item) =>
            item.codigo.toLowerCase().includes(value) ||
            item.descripcion.toLowerCase().includes(value) ||
            item.prestatario.toLowerCase().includes(value)
        );
        family.items = new_items;
        newList.push(family);
      }
      setJson_response({ ...json_response, tools: newList });
    } else {
      setJson_response(backupJson_response);
    }
  };

  // const onFocusSearch = () => {
  //   setBackupJson_response(json_response);
  // };

  const comboSearch = (e) => {
    const value = e.target.value;
    if (filterAvailable === value) {
      setFilterAvailable("");
      setJson_response(backupJson_response);
      return;
    }
    setFilterAvailable(value);
    var List = [...backupJson_response.tools];
    let newList = [];
    console.log(value);
    for (var i = 0; i < List.length; i++) {
      var family = {};
      family.familia = List[i].familia;
      family.color = List[i].color;
      var new_items;
      if (value === "prestadas") {
        new_items = List[i].items.filter((item) => item.prestatario !== "");
      } else if (value === "disponibles") {
        new_items = List[i].items.filter(
          (item) => item.prestatario === "" && item.en_mantenimiento === false
        );
      } else if (value === "no_funcional") {
        new_items = List[i].items.filter((item) => item.color === "orange");
      }
      family.items = new_items;
      newList.push(family);
    }
    setJson_response({ ...json_response, tools: newList });
  };

  const onCloseModalSalida = (status) => {
    if (status === 200) {
      setOpenModalSalida(false);
      setJson_response({});
      send_init_request();
    } else {
      setOpenModalSalida(false);
    }
    setMultipleOutputItems([]);
  };

  const onCloseModalHerramientas = (status) => {
    if (status === 200) {
      setOpenModalHerramientas(false);
      setJson_response({});
      send_init_request();
    } else {
      setOpenModalHerramientas(false);
    }
    setIsEdit(false);
  };

  const onCloseModalDevolucion = (status) => {
    if (status === 200) {
      setOpenModalDevolucion(false);
      setJson_response({});
      send_init_request();
    } else {
      setOpenModalDevolucion(false);
    }
  };

  const changeCheckboxOutput = (event, index, row) => {
    let { checked } = event.target;
    if (checked) {
      setMultipleOutputItems([...multipleOutputItems, row]);
    } else {
      let newMultipleOutput = multipleOutputItems.filter(
        (item) => item.codigo !== row.codigo
      );
      setMultipleOutputItems(newMultipleOutput);
  };
}

  return (
    <>
      {openModalInfoMtto && (
        <ModalInfoMtto
          open={openModalInfoMtto}
          onClose={() => setOpenModalInfoMtto(false)}
          initValuesModalInfoMtto={initValuesModalInfoMtto}
        />
      )}
      {openModalHerramientas && (
        <ModalHerramientas
          open={openModalHerramientas}
          onClose={onCloseModalHerramientas}
          initValuesModalHerramientas={initValuesModalHerramientas}
          isEdit={isEdit}
        />
      )}
      {openModalDevolucion && (
        <ModalDevolucion
          open={openModalDevolucion}
          onClose={onCloseModalDevolucion}
          initValuesModalDevolucion={initValuesModalDevolucion}
        />
      )}
      {openModalSalida && (
        <ModalSalida
          open={openModalSalida}
          onClose={onCloseModalSalida}
          initValuesModalSalida={initValuesModalSalida}
          isMultipleOutput={isMultipleOutput}
        />
      )}
      <div className="wrapped__container_top_bar ">
        <Loading isLoading={isLoading} />

        <div className="top-bar">
          <h3>Herramientas</h3>
        </div>
        <div className="search_div">
          <div className="search_container" style={{ flex: 0.5 }}>
            <input
              type="text"
              onChange={(event) => handleSearch(event.target.value)}
              // onFocus={() => onFocusSearch()}
            />
            <FontAwesomeIcon icon={faSearch} color="#383838" />
          </div>
          <div className="guides_tools" style={{flex: 0.6, justifyContent: "space-between"}}>
            <div className="sub_guide_tools" >
              <input
                type="Radio"
                name="filtro_alquilados"
                value="prestadas"
                className="inputselect nodisponibles"
                checked={filterAvailable === "prestadas"}
                onClick={(e) => {
                  comboSearch(e);
                }}
              />
              <p
                style={{
                  marginLeft: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "108px",
                }}
              >
                No Disponibles
              </p>
            </div>

            <div className="sub_guide_tools" >
              <input
                type="Radio"
                name="filtro_alquilados"
                value="disponibles"
                className="inputselect disponibles"
                checked={filterAvailable === "disponibles"}
                onClick={(e) => {
                  comboSearch(e);
                }}
              />

              <p
                style={{
                  marginLeft: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "108px",
                }}
              >
                &nbsp;Disponibles
              </p>
            </div>
            <div className="sub_guide_tools" >
              <input
                type="Radio"
                name="filtro_alquilados"
                value="no_funcional"
                className="inputselect nofuncional"
                checked={filterAvailable === "no_funcional"}
                onClick={(e) => {
                  comboSearch(e);
                }}
              />
              <p
                style={{
                  marginLeft: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "108px",
                }}
              >
                No Funcional
              </p>
            </div>
            
            <div className="sub_guide_tools" >
              <div
                className={`square_agregar_tools ${multipleOutputItems?.length <= 1?  "block_background": null}`}
                style={{ padding: "0px 10px"}}
                onClick={() => {
                  if (multipleOutputItems.length <= 1) return;
                  setInitValuesModalSalida(multipleOutputItems);
                  setIsMultipleOutput(true);
                  setOpenModalSalida(true);
                }}
              >
                <p className="col_white agregar_label_btn">Salida Multiple</p>
              </div>
            </div>

            <div className="sub_guide_tools" >
            
              {super_user_inventario && (
                <div
                  className="square_agregar_tools"
                  onClick={() => {
                    setIsEdit(false);
                    setOpenModalHerramientas(true);
                  }}
                >
                  <p className="col_white agregar_label_btn">Agregar</p>
                  <h3 className="col_white">+</h3>
                </div>
              )}
            </div>
            
          </div>
        </div>
        <div className="task-content">
          <table className="table_tasks">
            <tr className="rowHeaders">
              <th style={{ width: "3%" }} />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Codigo"
                txt_filter="codigo"
                width="8%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Descripción"
                txt_filter="descripcion"
                width="24%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Prestatario"
                txt_filter="prestatario"
                width="14%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Fecha Prestamo"
                txt_filter="fecha_prestamo"
                width="13%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Prox. Mtto"
                txt_filter="prox_mtto"
                width="13%"
              />
              {super_user_inventario && (
                <TableHeader col_name="Editar" width="8%" />
              )}
              <TableHeader col_name="Salida Múltiple" width="8%" />
              <TableHeader col_name="Salida" width="8%" />
              <TableHeader col_name="Devolución" width="8%" />
            </tr>
            {json_response?.tools?.map((row, index) => (
              <>
                <tr
                  className="rowTasks_title"
                  style={{ backgroundColor: `${row.color}` }}
                >
                  <td colSpan="10">{row.familia}</td>
                </tr>
                {row.items?.map((row, index) => (
                  <>
                    <tr className="rowTasks">
                      <td>
                        <div className="wrapper-estado">
                          <div
                            className="square_estado"
                            style={{ backgroundColor: `${row.color}` }}
                          />
                        </div>
                      </td>
                      <td>{row.codigo}</td>
                      <td style={{ textAlign: "left" }}>{row.descripcion}</td>
                      <td>{row.prestatario}</td>
                      <td>
                        <p>{row.fecha_prestamo}</p>
                        <p>{row.hora_prestamo}</p>
                      </td>

                      <td>
                        {new Date(`${row.prox_mtto}T00:00:00`) <= today ||
                        row.en_mantenimiento ? (
                          <FontAwesomeIcon
                            icon={faWrench}
                            color={row.en_mantenimiento ? "#266e45" : "red"}
                            className="iconRow"
                            onClick={() => InfoMaintenance(row)}
                          />
                        ) : (
                          row.prox_mtto
                        )}
                      </td>
                      {super_user_inventario && (
                        <td>
                          <FontAwesomeIcon
                            icon={faEdit}
                            color="#266e45"
                            className="iconRow"
                            onClick={() => {
                              setInitValuesModalHerramientas(row);
                              setOpenModalHerramientas(true);
                              setIsEdit(true);
                            }}
                          />
                        </td>
                      )}
                      <td>
                        <input
                          type="checkbox"
                          name="checkMultipleOutput"
                          disabled={row.prestada || row.en_mantenimiento ? true : false}
                          onChange={(e) => {
                            changeCheckboxOutput(e, index, row);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faSignOutAlt}
                          color={
                            row.prestada || row.en_mantenimiento
                              ? "#e6e6e6"
                              : "#266e45"
                          }
                          className="iconRow"
                          style={{ fontSize: "24px" }}
                          onClick={() => {
                            if (!row.prestada && !row.en_mantenimiento) {
                              setInitValuesModalSalida([row]);
                              setIsMultipleOutput(false);
                              setOpenModalSalida(true);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faUndo}
                          color={
                            row.prestada || row.en_mantenimiento
                              ? "#266e45"
                              : "#e6e6e6"
                          }
                          className="iconRow"
                          onClick={() => {
                            if (row.prestada || row.en_mantenimiento) {
                              setInitValuesModalDevolucion(row);
                              setOpenModalDevolucion(true);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}

export default Tools;
